<template>
  <div style="display: contents">
    <v-btn
      color="color3"
      x-small
      text
      @click.stop="openMe"
    >
      Add Membership
    </v-btn>
    <v-navigation-drawer
      right
      v-model="drawer"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="valid"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color2 color2Text--text" tile dense class="top">
          <v-toolbar-title>Membership Quick Add</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab
            small
            @click.stop="cancel"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="addMemebership"
                :disabled="!valid"
                :loading="loading"
                block
                class="py-9"
              >
                Add To Cart
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-container class="pt-6">
        <v-row dense>
          <v-col cols="12">
            <v-select
              :items="options"
              v-model="selectedProgram"
              label="Membership Program"
              return-object
              item-text="title"
            ></v-select>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row dense v-if="selectedProgram" :key="selectedProgram.id">
            <v-col cols="12" v-for="level in selectedProgram.levels" :key="level.id">
              <level-card
                :level="level"
                :actionText="`Add to ${player.firstName}`"
                @selected="onSelected"
              ></level-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
const LevelCard = () => import('@/components/Membership/LevelCard')

export default {
  props: ['player'],
  data () {
    return {
      drawer: false,
      options: [],
      loading: false,
      selectedProgram: null,
      selectedMembership: null
    }
  },
  computed: {
    cartItem () {
      if (!this.selectedMembership) return null
      const item = new CartItem()
      item.newMembership2(this.selectedProgram, this.selectedMembership, this.player)
      return item
    },
    valid () {
      return !!this.selectedMembership
    }
  },
  methods: {
    openMe () {
      this.getOptions()
      this.drawer = true
    },
    getOptions () {
      this.loading = true
      this.$http.get('/membership/programs')
        .then(r => {
          this.options = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    addMemebership () {
      this.addToCart()
    },
    cancel () {
      this.selectedProgram = null
      this.selectedMembership = null
      this.drawer = false
    },
    onSelected (level) {
      this.selectedMembership = level
    },
    addToCart () {
      if (this.cartItem) {
        this.$store.dispatch(actions.ADD_CART_ITEM, this.cartItem)
        this.$router.push({ name: 'checkout' })
      }
    }
  },
  components: {
    LevelCard
  }
}
</script>
